<template>
  <div class="">
    <div class="buildings__table table__titles">
      <div class="table__title">ID</div>
      <div class="table__title">Вулиця, № буд.</div>
      <div class="table__title">Поверхів</div>
      <div class="table__title">Рік</div>
      <div class="table__title">Аварійність</div>
      <div class="table__title">ОСББ/Управ.</div>
      <div class="table__title">Назва</div>
    </div>
    <div class="buildings__wrapper screen__wrapper">
      <router-view />
      <Row v-for="(building, i) in buildings" :key="building.id" :building="building" @click.native="info(building)" />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadBuildings"></infinite-loading>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Row from './Row'
import InfiniteLoading from 'vue-infinite-loading'
import InfoBuilding from '@/views/Buildings/Info'

export default {
  name: 'BuildingInfo',
  components: {
    Row,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapState({
      buildings: s => s.buildings.buildings,
      totalCount: s => s.buildings.pagination.total,
      page: s => s.buildings.page,
    }),
    hasMoreResults() {
      return this.buildings.length < this.totalCount && this.page > 1
    },
  },
  methods: {
    info(building) {
      this.$modal.display(InfoBuilding, {building}, {name: 'InfoBuilding', classes: 'modal modal-action',})
    },
    async loadBuildings() {
      this.isLoading = true
      try {
        await this.$store.dispatch('buildings/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
