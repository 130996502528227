var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "block" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "cell cell__num" }, [
        _vm._v(_vm._s(_vm.building.id))
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(_vm.building.street) + ", " + _vm._s(_vm.building.buildingNo)
        )
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v(_vm._s(_vm.building.floorsNumber))
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v(_vm._s(_vm.building.year != null ? _vm.building.year : "?"))
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v(
          _vm._s(
            _vm.building.emergency != null
              ? _vm.building.emergency
                ? "Так"
                : "Ні"
              : "?"
          )
        )
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v(_vm._s(_vm.building.cooperativeTypeName))
      ]),
      _c("div", { staticClass: "cell" }, [
        _vm._v('"' + _vm._s(_vm.building.cooperativeName) + '"')
      ]),
      !_vm.building.isAgentBlocked
        ? _c(
            "div",
            {
              staticClass: "cell",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              _c("Multiselect", {
                staticClass: "mSelect-dropdown",
                attrs: {
                  options: _vm.list,
                  searchable: false,
                  "show-labels": false,
                  "reset-after": true,
                  value: _vm.item,
                  placeholder: ""
                },
                on: { select: _vm.actions }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card__row" }, [
        _c("div", { staticClass: "cell cell__result" }, [
          _c("div", [_vm._v("ID " + _vm._s(_vm.building.id))])
        ]),
        _c("div", { staticClass: "cell cell__name" }, [
          _c("div", { staticClass: "user__name" }, [
            _vm._v(
              _vm._s(_vm.building.street) +
                ", " +
                _vm._s(_vm.building.buildingNo)
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "card__row" }, [
        _c("div", { staticClass: "card__cell card__cell--title" }, [
          _vm._v("Поверхів")
        ]),
        _c("div", { staticClass: "card__cell" }, [
          _c("span", { staticClass: "card__cell-date" }, [
            _vm._v(_vm._s(_vm.building.floorsNumber))
          ])
        ])
      ]),
      _c("div", { staticClass: "card__row" }, [
        _c("div", { staticClass: "card__cell card__cell--title" }, [
          _vm._v("Рік / Аварійність")
        ]),
        _c("div", { staticClass: "card__cell" }, [
          _c("span", { staticClass: "card__cell-date" }, [
            _vm._v(
              _vm._s(_vm.building.year) +
                " / " +
                _vm._s(_vm.building.emergency ? "Так" : "Ні")
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "card__row" }, [
        _c("div", { staticClass: "cell cell__name" }, [
          _c("div", { staticClass: "user__name" }, [
            _vm._v(_vm._s(_vm.building.cooperativeTypeName))
          ])
        ]),
        _c("div", { staticClass: "cell cell__result" }, [
          _c("div", [_vm._v('"' + _vm._s(_vm.building.cooperativeName) + '"')])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }