<template>
  <div class="block">
    <div class="row">
      <div class="cell cell__num">{{ building.id }}</div>
      <div class="cell">{{ building.street }}, {{ building.buildingNo }}</div>
      <div class="cell">{{ building.floorsNumber }}</div>
      <div class="cell">{{ building.year != null ? building.year : '?' }}</div>
      <div class="cell">{{ building.emergency != null ? (building.emergency ? 'Так' : 'Ні') : '?' }}</div>
      <div class="cell">{{ building.cooperativeTypeName }}</div>
      <div class="cell">"{{ building.cooperativeName }}"</div>
      <div v-if="!building.isAgentBlocked" class="cell" @click.stop.prevent>
        <Multiselect
          class="mSelect-dropdown"
          :options="list"
          :searchable="false"
          :show-labels="false"
          :reset-after="true"
          :value="item"
          placeholder
          @select="actions"
        />
      </div>
    </div>
    <div class="card">
      <div class="card__row">
        <div class="cell cell__result">
          <div>ID {{ building.id }}</div>
        </div>
        <div class="cell cell__name">
          <div class="user__name">{{ building.street }}, {{ building.buildingNo }}</div>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Поверхів</div>
        <div class="card__cell">
          <span class="card__cell-date">{{ building.floorsNumber }}</span>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Рік / Аварійність</div>
        <div class="card__cell">
          <span class="card__cell-date">{{ building.year }} / {{ building.emergency ? 'Так' : 'Ні' }}</span>
        </div>
      </div>
      <div class="card__row">
        <div class="cell cell__name">
          <div class="user__name">{{ building.cooperativeTypeName }}</div>
        </div>
        <div class="cell cell__result">
          <div>"{{ building.cooperativeName }}"</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import UploadBuilding from '@/views/Buildings/Upload'
import localConstants from '@/utils/localConstants'
import {mapGetters} from 'vuex'

export default {
  name: 'Row',
  props: ['building'],
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(['user']),
  },
  data: () => ({
    list: ['Редагувати'],
    item: '',
    localConstants
  }),
  created() {
    if (this.user.role === localConstants.USER_ROLES.ADMIN) {
      this.list.push('Видалити')
    }
  },
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        const style = this.$modal.styles
        this.$modal.show(
          UploadBuilding,
          {building: this.building},
          {...style, name: 'modalUploadBuilding'},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('buildings/delete', this.building.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
